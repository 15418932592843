<template>
  <div class="out" ref="out">
    <!-- <van-pull-refresh v-model="isLoading" @refresh="loadDoodState"> -->
    <div class="header">
      <div class="logo Title">
        <div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt="" @click="$router.back()" />
          {{ $t('main.myt') }}</div>
      </div>
      <div class="right-icon">
        <img src="../assets/img/logo-icon.png" alt="">
        <div class="cc-wc">
          {{ $t('main.title') }}
        </div>
      </div>
    </div>
    <div class="tarbar">
      <div class="item" :class="is_cul?'':'active'" @click="is_cul = false">珍宝</div>
      <div class="item" :class="is_cul?'active':''" @click="is_cul = true" >文创品</div>
    </div>
    <div v-show="!is_cul" >
      <div class="body" v-if="list.length">
        <div v-show="actIndex == 0">
          <div class="items" v-for="(item, index) in list" :key="index">
            <!-- <div class="flex" style="margin-bottom:0.1rem" v-if="item.goods">

              <div class="check-box">
                <input type="checkbox" :id="'sm' + item.id" :checked="item.goods.is_can_buy == 1 ? true : false"
                  @change="CanBuy($event, item.id, item.goods.id)" />
                <label :for="'sm' + item.id">{{ $t('main.publicS') }}</label>
                <img class="question" v-show="help.want_to_buy" @click="showContent('want_to_buy')"
                  src="../assets/img/question.png" alt="" srcset="">
              </div>

            </div> -->
            <div class="flex_sp">
              <div class="good_img" @click="goDetail(item.goods)">
                <img v-lazy="
                  (item.goods ? item.goods.pictures[0] + '?width=200' : 'http://t.6a.com/87/01193881f683a494.png' + '?width=200')
                " alt="" />
              </div>
              <div class="zb-gl-item-top-right">
                <div class="zb-title" @click="goDetail(item.goods)">
                  {{ item.goods ? item.goods.name : $t('main.trhbdetele') }}
                </div>
                <div class="zb-code">
                  {{ $t('main.gwtc') }}{{
                    item.goods
                    ? item.goods.serial_number
                    : "--------"
                  }}
                </div>
                <div class="zb-issue-date">
                  {{ $t('main.relT') }}{{ item.goods ? item.goods.created_at : '----' }}
                </div>
                <div class="zb-value">{{ $t('main.mv') }}：￥{{ item.goods ? thousands(item.goods.price) : '----' }}</div>
              </div>
            </div>
            <div class="zb_renzhen" v-if="item.goods">
              <div class="zb-rz-adopt">
                <img src="../assets/img/my-zb-adopt-icon.png" alt="" />
                <span>{{ $t('main.tpc') }}</span>
              </div>
              <div class="zb-tips-item zb-rz-rare-adopt" v-if="item.goods && item.goods.is_rare == 1">
                <div>
                  <img src="../assets/img/rare-icon-new.png" alt="" />
                </div>
                <span>{{ $t('main.ptrtc') }}</span>
              </div>
            </div>

            <div v-if="item.goods && item.status == 3 && item.goods.is_show == 1" class="flex flex-border-top">
              <div class="list_button" style="background-color: #9a2a25" @click="gozhuanran(item)">
                <div><img src="../assets/img/zy_box.png" alt="" /></div>
                {{ $t('main.authtran') }}
                <img class="is_info" v-show="item.child" src="../assets/img/tips.png" alt="" />
              </div>

              <!-- <div class="list_button" style="background-color: #d38f45; margin-left: 0.02rem" @click="goBI(item)">
                <div><img src="../assets/img/qgsq.png" alt="" /></div>
                {{ $t('main.purshRe') }}
                <img class="is_info" v-show="item.goods.has_new_buy == 1" src="../assets/img/tips.png" alt="" />
              </div> -->
              <div class="list_button" style="background-color: #748C76;margin-left: 0.02rem;box-sizing: border-box;"
                @click="actionS(item)">
                <div><img src="../assets/img/download.png" alt="" /></div>
                {{ $t('main.dl') }}
              </div>
              <!-- <div class="list_button" style="background-color: #A45840;margin-left: 0.02rem;box-sizing: border-box;">
                      <img src="../assets/img/add.png" alt="">
                      <div @click="adtiViola(item.goods.id)">{{$t('main.detailUpload')}}</div>
                    </div> -->
            </div>
            <div v-if="item.status == 4 && item.goods && item.goods.is_show == 1"
              style="display: flex; align-items: center; margin-top: 0.13rem">
              <div class="xiug" :style="$i18n.locale == 'en' ? 'width:1.5rem;background-size:1.3rem 0.35rem' : ''"
                @click="goChange(item.id)">
                <div><img src="../assets/img/tip.png" alt="" /></div>
                {{ $t('main.modifyInfo') }}
              </div>
              <div class="fail_reason">{{ item.fail_reason }}</div>
            </div>
            <div v-if="item.status == 1 && item.goods && item.goods.is_show == 1" class="rzh">
              {{ $t('main.ciur') }}
            </div>
            <div v-if="item.goods && item.goods.is_show == 0" class="rzh">{{ $t('main.blocked') }}</div>

          </div>

          <div class="updatedd" @click="updateName" style="display:none">
            <img src="../assets/img/updatedd.png" alt="">
          </div>

          <!-- <div class="pop pop-cy-rz" v-show="qrcode1" @click="closeQr1">
              <div class="pop-qrcode">
                <img :src="nowZS" alt="" />
              </div>
            </div> -->
          <div class="pop pop-cy-rz" v-show="qrcode1" @click="closeQr1">
            <div class="pop-qrcode">
              <div>
                <img :src="nowZS" alt="" />
              </div>
              <div class="pop-tishi"><img src="../assets/img/click-logo-zm.png" alt=""> {{ $t('main.lodqr') }}</div>
            </div>
          </div>
        </div>

      </div>

      <div v-if="!list.length" class="empty_wrap">
      
        <div class="empty">
          <img src="../assets/img/no-data-tishi.png" alt="" />
          {{ $t('main.ntcn') }}
        </div>
      </div>
    </div>
    <div v-show="is_cul" >
      <div class="body" v-if="cul_list.length">
        <div v-show="actIndex == 0">
          <div class="items" v-for="(item, index) in cul_list" :key="index">
            <!-- <div class="flex" style="margin-bottom:0.1rem" v-if="item.goods">

              <div class="check-box">
                <input type="checkbox" :id="'sm' + item.id" :checked="item.goods.is_can_buy == 1 ? true : false"
                  @change="CanBuy($event, item.id, item.goods.id)" />
                <label :for="'sm' + item.id">{{ $t('main.publicS') }}</label>
                <img class="question" v-show="help.want_to_buy" @click="showContent('want_to_buy')"
                  src="../assets/img/question.png" alt="" srcset="">
              </div>

            </div> -->
            <div class="flex_sp">
              <div class="good_img" @click="goDetail1(item.goods)">
                <img v-lazy="
                  (item.goods ? item.goods.pictures[0] + '?width=200' : 'http://t.6a.com/87/01193881f683a494.png' + '?width=200')
                " alt="" />
              </div>
              <div class="zb-gl-item-top-right">
                <div class="zb-title" @click="goDetail1(item.goods)">
                  {{ item.goods ? item.goods.name : '文创品已删除' }}
                </div>
                <div class="zb-code">
                  长城文创品编码：{{
                    item.goods
                    ? item.goods.serial_number
                    : "--------"
                  }}
                </div>
                <div class="zb-issue-date">
                  {{ $t('main.relT') }}{{ item.goods ? item.goods.created_at : '----' }}
                </div>
                
              </div>
            </div>
            <div class="zb_renzhen" v-if="item.goods">
              <div class="zb-rz-adopt">
                <img src="../assets/img/my-zb-adopt-icon.png" alt="" />
                <span>{{ $t('main.tpc') }}</span>
              </div>
              <div class="zb-tips-item zb-rz-rare-adopt" v-if="item.goods && item.goods.is_rare == 1">
                <div>
                  <img src="../assets/img/rare-icon-new.png" alt="" />
                </div>
                <span>{{ $t('main.ptrtc') }}</span>
              </div>
            </div>

            <div v-if="item.goods && item.status == 3 && item.goods.is_show == 1" class="flex flex-border-top">
              <div class="list_button" style="background-color: #9a2a25" @click="gozhuanran1(item)">
                <div><img src="../assets/img/zy_box.png" alt="" /></div>
                {{ $t('main.authtran') }}
                <img class="is_info" v-show="item.child" src="../assets/img/tips.png" alt="" />
              </div>

              
              <div class="list_button" style="background-color: #748C76;margin-left: 0.02rem;box-sizing: border-box;"
                @click="actionS1(item)">
                <div><img src="../assets/img/download.png" alt="" /></div>
                {{ $t('main.dl') }}
              </div>
              <!-- <div class="list_button" style="background-color: #A45840;margin-left: 0.02rem;box-sizing: border-box;">
                      <img src="../assets/img/add.png" alt="">
                      <div @click="adtiViola(item.goods.id)">{{$t('main.detailUpload')}}</div>
                    </div> -->
            </div>
            <div v-if="item.status == 4 && item.goods && item.goods.is_show == 1"
              style="display: flex; align-items: center; margin-top: 0.13rem">
              <div class="xiug" :style="$i18n.locale == 'en' ? 'width:1.5rem;background-size:1.3rem 0.35rem' : ''"
                @click="goChange(item.id)">
                <div><img src="../assets/img/tip.png" alt="" /></div>
                {{ $t('main.modifyInfo') }}
              </div>
              <div class="fail_reason">{{ item.fail_reason }}</div>
            </div>
            <div v-if="item.status == 1 && item.goods && item.goods.is_show == 1" class="rzh">
              {{ $t('main.ciur') }}
            </div>
            <div v-if="item.goods && item.goods.is_show == 0" class="rzh">{{ $t('main.blocked') }}</div>

          </div>

          <div class="updatedd" @click="updateName" style="display:none">
            <img src="../assets/img/updatedd.png" alt="">
          </div>

          <!-- <div class="pop pop-cy-rz" v-show="qrcode1" @click="closeQr1">
              <div class="pop-qrcode">
                <img :src="nowZS" alt="" />
              </div>
            </div> -->
          <div class="pop pop-cy-rz" v-show="qrcode1" @click="closeQr1">
            <div class="pop-qrcode">
              <div>
                <img :src="nowZS" alt="" />
              </div>
              <div class="pop-tishi"><img src="../assets/img/click-logo-zm.png" alt=""> {{ $t('main.lodqr') }}</div>
            </div>
          </div>
        </div>

      </div>

      <div v-if="!cul_list.length" class="empty_wrap">
      
        <div class="empty">
          <img src="../assets/img/no-data-tishi.png" alt="" />
          {{ $t('main.ntcn') }}
        </div>
      </div>
    </div>
    <!-- </van-pull-refresh> -->
    <img src="../assets/img/guanli_qrcode.png" id="border" style="display:none" alt="">
    <privacy ref="privacy"></privacy>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { ImagePreview, Dialog } from "vant";
import privacy from "../components/privacy.vue"

export default {
 components: {
    privacy
  },
  data() {
    return {
      list: [],
      actIndex: 0,
      touching: false,
      startY: 0,
      top: 0,
      isLoading: false,
      qrcode1: false,
      nowZS: "",
      protocol: "http",
      P_list: [],
      page: 1,
      is_next: false,
      loading1: false,
      p_is_next: false,
      showUpdate: false,
      is_cul_next:false,
      cul_page:1,
      help: {},
      is_show: 0,
      cul_list:[],
      is_cul:false
    };
  },
  methods: {
    showContent(key) {
      Dialog.alert({ message: this.help[key], confirmButtonColor: "#2d71d7" });
    },
    CanBuy(e, id, goods_id) {


      let statusPd = e.target.checked ? 1 : 0
      this.axios
        .post("/goods/setCanBuy", {
          id: id,
          goods_id: goods_id,
          status: statusPd
        })
        .then((res) => {
          if (res.data.code == 0) weui.topTips(this.$t('main.setSucc'), 1500);
        });
    },
    goChange(id) {
       if(!this.$isWeiXin()){
				weui.alert('请在微信浏览器打开')
				return
			}
      let url = location.protocol + "//" + location.host;
      this.$router.push("/zyinfo?id=" + id+'&is_cul='+(this.is_cul? 1:0));
    },

    // loadDoodState() {
    //   if (this.actIndex == 0) {
    //     this.isLoading = true;
    //     this.axios.get("/collection/mine").then((res) => {
    //       this.isLoading = false;
    //       this.list = res.data.data.items;
    //       this.is_next = res.data.data.hasMorePages;
    //     });
    //   } else {
    //     this.isLoading = true;
    //     this.axios.post("collection/getMyApplyBuy", { page: 1 }).then((res) => {
    //       this.P_list = res.data.data.items;
    //       this.p_is_next = res.data.data.hasMorePages;
    //       this.isLoading = false;
    //     });
    //   }
    // },
    goDetail(goods) {
      if (!goods) {
        weui.alert(this.$t('main.trisdepc'), { buttons: [{ label: this.$t('main.confIrm') }] });
        return;
      }
      if (goods.is_show == 0) {
        weui.alert(this.$t('main.trisbanpc'), { buttons: [{ label: this.$t('main.confIrm') }] });
        return;
      }
      this.$router.push(`/detail/${goods.id}?status=1`);
    },
    goDetail1(goods) {
      if (!goods) {
        weui.alert(this.$t('main.trisdepc'), { buttons: [{ label: this.$t('main.confIrm') }] });
        return;
      }
      if (goods.is_show == 0) {
        weui.alert(this.$t('main.trisbanpc'), { buttons: [{ label: this.$t('main.confIrm') }] });
        return;
      }
      this.$router.push(`/detail_cul/${goods.id}?status=1`);
    },
    gozhuanran(item) {
      if(!this.$isWeiXin()){
				weui.alert('请在微信浏览器打开')
				return
			}
      this.$router.push({
        path: `/zhuanran`,
        query: {
          goods_id: item.goods.id,
          id: item.id,
          name: item.goods.name,
          serial_number: item.goods.serial_number,
          transfer_fee: item.goods.level.transfer_fee,
        },
      });
    },
    gozhuanran1(item) {
      if(!this.$isWeiXin()){
				weui.alert('请在微信浏览器打开')
				return
			}
      this.$router.push({
        path: `/zhuanran_cul`,
        query: {
          goods_id: item.goods.id,
          id: item.id,
          name: item.goods.name,
          serial_number: item.goods.serial_number,
          transfer_fee: item.goods.level.transfer_fee,
        },
      });
    },
    actionS(item) {
      weui.actionSheet([
        {
          label: this.$t('main.downqr'),
          onClick: () => {
            this.DownLoadQRcode(item);
          },
        },
        {
          label: this.$t('main.downce'),
          onClick: () => {
            this.DownZS(item);
          },
        },
      ]);
    },
    actionS1(item) {
      weui.actionSheet([
        {
          label: this.$t('main.downqr'),
          onClick: () => {
            this.DownLoadQRcode1(item);
          },
        },
        {
          label: this.$t('main.downce'),
          onClick: () => {
            this.DownZS(item);
          },
        },
      ]);
    },
    DownLoadQRcode(item) {
      let url = location.protocol + "//" + location.host;
      let div = document.createElement("div");
      let code = new QRCode(div, {
        text: url + "/detail/" + item.goods.id, //要生成二维码的网址
        width: 410, //图像宽度
        height: 410, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        margin: 0, //外边距
        correctLevel: QRCode.CorrectLevel.L, //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
      });
      let canvas = code._el.querySelector("canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
      let qr_ctx = canvas.getContext('2d')
      let qr_img = qr_ctx.getImageData(0, 0, canvas.width, canvas.height)


      // ctx.font="50px Arial";
      // ctx.fillStyle = '#FFFFFF'
      // ctx.textAlign = "center"


      var canvas1 = document.createElement('canvas')
      var ctx = canvas1.getContext('2d')
      var border = document.getElementById('border')





      canvas1.width = border.width
      canvas1.height = border.height


      var qr_img_top = 160
      var nameHeight = 100
      var borderHeight = 640
      if (item.name.length > 13) {
        qr_img_top = 200
        nameHeight = 130
        borderHeight = canvas1.height
      }


      ctx.drawImage(border, 0, 0, canvas1.width, borderHeight)

      this.drawText(item.goods.name, canvas1.width / 2, nameHeight, 380, ctx)
      // ctx.fillRect(30,100,430,430)
      ctx.fillStyle = '#FFFFFF'
      ctx.fillRect(30, qr_img_top - 10, 430, 430)
      ctx.putImageData(qr_img, 40, qr_img_top)
      this.nowZS = canvas1.toDataURL("image/png");

      this.qrcode1 = true;
    },
    DownLoadQRcode1(item) {
      let url = location.protocol + "//" + location.host;
      let div = document.createElement("div");
      let code = new QRCode(div, {
        text: url + "/detail_cul/" + item.goods.id, //要生成二维码的网址
        width: 410, //图像宽度
        height: 410, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        margin: 0, //外边距
        correctLevel: QRCode.CorrectLevel.L, //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
      });
      let canvas = code._el.querySelector("canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
      let qr_ctx = canvas.getContext('2d')
      let qr_img = qr_ctx.getImageData(0, 0, canvas.width, canvas.height)


      // ctx.font="50px Arial";
      // ctx.fillStyle = '#FFFFFF'
      // ctx.textAlign = "center"


      var canvas1 = document.createElement('canvas')
      var ctx = canvas1.getContext('2d')
      var border = document.getElementById('border')





      canvas1.width = border.width
      canvas1.height = border.height


      var qr_img_top = 160
      var nameHeight = 100
      var borderHeight = 640
      if (item.name.length > 13) {
        qr_img_top = 200
        nameHeight = 130
        borderHeight = canvas1.height
      }


      ctx.drawImage(border, 0, 0, canvas1.width, borderHeight)

      this.drawText(item.goods.name, canvas1.width / 2, nameHeight, 380, ctx)
      // ctx.fillRect(30,100,430,430)
      ctx.fillStyle = '#FFFFFF'
      ctx.fillRect(30, qr_img_top - 10, 430, 430)
      ctx.putImageData(qr_img, 40, qr_img_top)
      this.nowZS = canvas1.toDataURL("image/png");

      this.qrcode1 = true;
    },
    DownZS(item) {
      ImagePreview({ images: [item.goods.certificate], showIndex: false });
    },
    closeQr1() {
      this.qrcode1 = false;
    },
    goBI(item) {
      this.$router.push({
        path: "/buy_info/" + item.id,
        query: {
          status: 1,
          goods_id: item.goods.id,
        },
      });
    },
    goqiugou(index) {
      this.actIndex = index;
      this.page = 1;
      history.replaceState(null, null, "/zbbox?status=" + index);
    },
    // goTalk(item, index) {
    //   if (item.status == 0) {
    //     weui.alert(this.$t('main.tophnv'));
    //     return;
    //   }
    //   history.replaceState(null, null, "/zbbox?status=1");
    //   this.P_list[index].has_new = 0;
    //   this.$router.push({
    //     path: "/talk",
    //     query: {
    //       apply_id: item.id,
    //       type: 2,
    //       goods_id: item.goods_id,
    //     },
    //   });
    // },
    throttle(fn, time) {
      let canRun = true;
      return function () {
        if (!canRun) return;
        canRun = false;
        setTimeout(() => {
          fn.apply(this);
          canRun = true;
        }, time);
      };
    },
    drawText(test, x, y, width, context) {
      var chr = test.split("");
      var temp = "";
      var row = [];

      context.font = "30px Arial";
      context.fillStyle = "#fcf6dc";
      context.textAlign = "center"

      for (var a = 0; a < chr.length; a++) {
        if (context.measureText(temp).width < width) {
          ;
        }
        else {
          row.push(temp);
          temp = "";
        }
        temp += chr[a];
      }

      row.push(temp);
      if (row.length == 1) {
        y += 25
      }
      for (var b = 0; b < row.length; b++) {
        context.fillText(row[b], x, y + (b * 50));
      }
    },
    scrollFn(e) {
      if (
        this.$refs.out.scrollHeight -
        this.$refs.out.scrollTop -
        this.$refs.out.clientHeight <=
        20
      ) {
        if (this.actIndex == 0) {
          if (this.is_next && !this.loading1) {
            this.page++;
            this.loading1 = true;
            this.axios
              .get("/collection/mine?page=" + this.page, {})
              .then((res) => {
                if(this.$i18n.locale == 'en'){
                  for(let i = 0; i < res.data.data.items.length; i++){
                    if(res.data.data.items[i].goods){
                      res.data.data.items[i].goods.name = res.data.data.items[i].goods.name_intl ? res.data.data.items[i].goods.name_intl:res.data.data.items[i].goods.name
                    }
                    
                  }
                }
                let list = res.data.data.items;
                this.list = this.list.concat(list);
                this.loading1 = false;
                this.is_next = res.data.data.hasMorePages;
              });
          }
        } else {
          if (this.p_is_next && !this.loading1) {
            this.page++;
            this.loading1 = true;
            this.axios
              .post("collection/getMyApplyBuy", { page: this.page })
              .then((res) => {
                let list = res.data.data.items;
                this.P_list = this.P_list.concat(list);
                this.loading1 = false;
                this.p_is_next = res.data.data.hasMorePages;
              });
          }
        }
      }
    },
    updateName() {
      weui.alert(
        this.$t('main.uptrholdinfo'),
        {
          buttons: [
            { label: this.$t('main.cancel'), onClick: () => { } },
            {
              label: this.$t('main.confIrm'),
              onClick: () => {
                window.location.href =
                  "/api/auth/login?url=" +
                  location.protocol +
                  "//" +
                  location.host +
                  this.$route.fullPath +
                  "?updated=1";
              },
            },
          ],
        }
      );
    },

    thousands(num) {
      var splits = [], res = [];
      var splits = num.toString().split(".");
      splits[0].split("").reverse().map(function (item, i) {
        if (i % 3 == 0 && i != 0) { res.push(","); }
        res.push(item);
      });
      return res.reverse().join("") + (splits.length > 1 ? "." + splits[1] : "");
    },
    updateddd() {
      this.axios.post("collection/updateCollectionRecordsName").then((res) => {
        if (res.data.code == 0) {
          setTimeout(() => {
            weui.alert(this.$t('main.updateSucc'), { buttons: [{ label: this.$t('main.confIrm') }] });
          }, 500);
        }
      });
    },
    // 细节上传
    adtiViola(id) {
      this.$router.push(`/imgUpdate/${id}`)
    },
    getQueryString(name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
			var r = window.location.search.substr(1).match(reg);
			if (r != null) return decodeURIComponent(r[2]);
			return null;
		}
  },
  async mounted() {
    if (this.$route.query.status) {
      this.actIndex = this.$route.query.status;
    }
    this.$refs.out.addEventListener("scroll", this.throttle(this.scrollFn, 500));
    this.protocol = location.protocol;
    if (this.$route.query.code) {
       let querys= ''
            if(this.$route.query.phone){
                let phone =this.getQueryString('phone')
                let nickName = this.getQueryString('phone_code')
                querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`
            } 
      await this.axios
        .get("/auth/nt?code=" + this.$route.query.code+querys)
        .then((res) => {
          if(res.data.code == -1 && res.data.msg == '请填写手机号'){
                    
                    this.$refs.privacy.openPopup()
                    return
                }
          window.localStorage.setItem("user_token", res.data.data.token);
          console.log(this.$route.query);
          if (this.$route.query.updated) {
            this.updateddd();
          }
        })
        .catch(() => { });
    }

    // this.axios.post("collection/getMyApplyBuy", { page: 1 }).then((res) => {
    //   this.P_list = res.data.data.items;
    //   this.p_is_next = res.data.data.hasMorePages;
    // });
    let no_list = [];
    await this.axios.get("/collection/mine").then((res) => {
      if(this.$i18n.locale == 'en'){
        for(let i = 0; i < res.data.data.items.length; i++){
          if(res.data.data.items[i].goods){
            res.data.data.items[i].goods.name = res.data.data.items[i].goods.name_intl ? res.data.data.items[i].goods.name_intl:res.data.data.items[i].goods.name
          }
        }
      }
     
      this.list = res.data.data.items;
      this.is_next = res.data.data.hasMorePages;
      no_list = this.list.filter((item) => {
        return item.is_custom == 0;
      });
    });
    await this.axios.get('collectionder/mine').then((res)=>{
      if(this.$i18n.locale == 'en'){
        for(let i = 0; i < res.data.data.items.length; i++){
          if(res.data.data.items[i].goods){
            res.data.data.items[i].goods.name = res.data.data.items[i].goods.name_intl ? res.data.data.items[i].goods.name_intl:res.data.data.items[i].goods.name
          }
        }
      }
     
      this.cul_list = res.data.data.items;
      this.is_cul_next = res.data.data.hasMorePages;
      
    })
    this.axios.get("/auth/me").then((res) => {
      let avatar = res.data.data.user.avatar;
      let name = res.data.data.user.username;
      console.log(avatar, name, no_list);
      if (
        no_list.findIndex((item) => {
          return item.avatar != avatar || item.name != name;
        }) != -1
      ) {
        this.showUpdate = true;
      }
    });
    if (this.$i18n.locale == 'en') {
      let data = JSON.parse(localStorage.getItem('helpinfo'))
      Object.keys(data).forEach((item) => {
        if (item.indexOf('_intl') != -1) {
          let label = item.split('_intl')[0]
          if (data[item]) {
            data[label] = data[item]
          }
        }
      })
      this.help = data
    } else {
      this.help = JSON.parse(localStorage.getItem('helpinfo'))
    }
  },
};
</script>

<style scoped>
.out {
  min-height: 100vh;
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;

  background: url(../assets/img/index_bg.png) no-repeat center;
  background-size: 100% 100%;
}

.body {
  padding: 0.15rem;
  /* background-attachment: fixed; */
}

.header {
  padding: 0.05rem 0.15rem 0.09rem;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  /* line-height: 0; */
  align-items: center;
  background: #FFFFFF;
}

.Title {
  font-size: 0.16rem;
  position: relative;
}



.Chinese {
  color: #1b6a21;
  /* top: -0.2rem; */
  left: 0;
  position: relative;
  text-align: left;
  font-size: 0.18rem;
}

.cc-wc {
  font-size: 0.1rem;
  color: #e9630a;
}

.right-icon {
  /* width: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.right-icon img {
  display: block;
  width: 100%;
  height: 100%;
  width: 0.65rem;
}

.items {
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0.15rem 0.1rem;
  margin-bottom: 0.15rem;
  box-shadow: 0 0 10px 0px #b1b1b1;
}

.flex_sp {
  display: flex;
  margin-bottom: 0.025rem;
}

.flex {
  display: flex;

}

.flex-border-top {
  border-top: 0.01rem solid #eee;
}

.zb-gl-item-top-right {
  margin-left: 0.175rem;
  font-size: 0.14rem;
  white-space: nowrap;

  color: #939393;
  text-align: left;
}

.zb-gl-item-top-right>div {
  margin-bottom: 0.1rem;
}

.zb-title {
  max-width: 2rem;
  font-size: 0.15rem;
  line-height: 0.15rem;
  font-weight: 500;
  color: #171717;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zb_renzhen {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.zb-rz-adopt {
  color: #9a2a25;
}

.zb-rz-adopt img {
  /* width: 1rem; */
  height: 0.25rem;
  margin-right: 0.05rem;
}

.zb-rz-adopt {
  display: flex;
  align-items: center;
  margin-bottom: 0.05rem;
}

.zb-tips-item {
  display: flex;
  align-items: center;
}

.zb-tips-item img {
  width: 0.18rem;
}

.zb-rz-rare-adopt {
  color: #9a2a25;
}

.zb-rz-rare-adopt div:nth-child(1) {
  width: 0.19rem;
  height: 0.19rem;
  /* border-radius: 50%;
    	box-shadow: 0px 0px 6px 0 #f46246; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.1rem;
}

.zb-rz-rare-adopt div:nth-child(1) img {
  width: 100%;
  height: 100%;
  display: block;
}

.xiug {
  /* margin-top: 0.13rem; */
  width: 1.125rem;
  background: #ff5656;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 0.12rem;
  line-height: 0.15rem;
  height: 0.4rem;
  background: url(../assets/img/my-zb-btn-xg.png) no-repeat center rgb(154, 42, 37);
  background-size: 1.07rem;
}

.xiug img {
  width: 0.15rem;
  height: 0.15rem;
  margin-right: 0.07rem;
  display: block;
}

.list_button {
  margin-top: 0.15rem;
  margin-right: 0.04rem;
  position: relative;
  width: .77rem;
  height: .4rem;
  border-radius: .05rem;
  background: url(../assets/img/zb_biankuang.png) no-repeat center;
  background-position: 50%;
  background-size: contain;
  background-color: #e37c4f;
  color: #fff;
  word-break: keep-all;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 0.12rem;
  justify-content: center;
}

.list_button img {
  width: 0.13rem;
  height: 0.13rem;
  margin-right: 0.05rem;
  display: block;
}

.rzh {
  width: 100%;
  text-align: center;
  margin-top: 0.13rem;
  color: #ff5656;
  font-size: 0.12rem;
}

.empty {
  border-radius: 0.1rem;
  margin: 0.4rem auto;
  width: 3.205rem;
  color: #b28d80;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.16rem;
  text-align: center;
  padding-bottom: 0.29rem;
}

.empty img {
  margin-top: 0.84rem;
  margin-bottom: 0.30rem;
  width: 1.975rem;
  object-fit: contain;
  display: block;
}

.is_info {
  width: 0.16rem !important;
  height: 0.16rem !important;
  position: absolute;
  right: -0.06rem;
  margin: 0 !important;
  top: -0.06rem;
  background: #fff;
  border-radius: 50%;
}

.fail_reason {
  width: 2rem;
  margin-left: 0.1rem;
  font-size: 0.15rem;
  color: rgb(154, 42, 37);
}

.good_img {
  width: 1.08rem;
  height: 1.08rem;
  border-radius: 0.06rem;
  overflow: hidden;
}

.good_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.pop-cy-rz .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pop-cy-rz .center input {
  width: 100%;
  height: 0.44rem;
  outline: none;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  border: none;
  padding-left: 0.1rem;
  margin-top: 0.26rem;
  box-sizing: border-box;
}

.pop-cy-rz .pop-tishi {
  color: white;
  margin-top: 0.1rem;
  font-size: 0.14rem;
}

.pop-cy-rz .pop-tishi>img {
  width: 0.14rem;
  vertical-align: middle;
  margin-right: 0.06rem;
}

.pop-qrcode {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: url(../assets/img/longtap.png) no-repeat;
  background-size: 100% 100%; */
  width: 2.56rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pop-qrcode img {
  width: 2.27rem;
  /* height: 2.27rem;
  padding: 0.1rem;
  box-sizing: border-box;
  background-color: #fff; */
  object-fit: contain;
}

.pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  /* display: none; */
}

.updatedd {
  position: fixed;
  bottom: 0.8rem;
  right: 0.3rem;
}

.updatedd img {
  width: 0.3rem;
  height: 0.3rem;
}

.check-box {
  font-size: 0.15rem;
  font-weight: 500;
  line-height: 0.24rem;
  letter-spacing: 0px;
  color: #2a2a2a;

  left: 0.2rem;
  top: -0.12rem;
  /* width: 0.4rem; */
  /* height: 0.24rem; */
  display: flex;
  align-items: center;
}

.check-box label {
  /* margin-left: 0.07rem;
				    padding-left: 0.25rem; */
  display: flex;
  align-items: center;
}

.check-box input {
  display: none;
}

.check-box label::before {
  display: inline-block;
  content: "";
  width: 0.19rem;
  height: 0.19rem;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #c1c1c1;
  margin-right: 0.07rem;
  /* left: 0;
			       top: 50%;
			       transform: translateY(-50%);
			       position: absolute; */
}

.check-box input:checked+label::before {
  display: inline-block;
  content: "";
  width: 0.19rem;
  height: 0.19rem;
  margin-right: 0.07rem;
  background: url(../assets/img/gou.png) no-repeat center;
  background-size: 0.11rem 0.08rem;
  box-shadow: none;
  background-color: #082958;
  /* position: absolute;
			       left: 0.05rem;
			       top: 50%;
			       transform: translateY(-50%); */
}

.question {
  width: 0.15rem;
  height: 0.15rem;
  margin-right: 0.05rem;
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  margin-left: 0.05rem;
}
.tarbar{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 0.1rem;
  color: #929292;
  font-size: 0.18rem;
}
.tarbar .item{
  padding-bottom: 0.08rem;
}
.tarbar .item.active{
  color: #a72d28;
  border-bottom: solid 1px #a72d28;
}
</style>